<template>
  <div class="QuestionnaireRadio">
    <p v-if="isError" class="QuestionnaireRadio__error">
      {{ errorText }}
    </p>
    <ul class="QuestionnaireRadio__list">
      <li
        class="QuestionnaireRadio__item"
        :class="{
          [`QuestionnaireRadio__item--select`]: checked == option.key,
        }"
        v-for="option in options"
        :key="option.key"
      >
        <label class="QuestionnaireRadio__menuLabel">
          <input
            class="QuestionnaireRadio__input"
            type="radio"
            :value="option.key"
            v-model="inputValue"
          />
          <Icon
            class="QuestionnaireRadio__icon"
            :iconName="checked == option.key ? 'RadioOn' : 'RadioOff'"
          />
          <span class="QuestionnaireRadio__text">{{ option.displayName }}</span>
        </label>
        <br />
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import Icon from "@/components/Atoms/IconBase/index.vue";
import { FormOption } from "@/pages/useSurveyResponse";

interface Props {
  value?: string;
  options?: FormOption[];
  errorText?: string;
  isError?: boolean;
}

// 設定
const props = defineProps<Props>();
const checked = ref(props.value ?? "");

const emit = defineEmits(["update:value"]);
// 入力値
const inputValue = computed({
  get: () => checked.value,
  set: (val) => {
    checked.value = val;
    emit("update:value", checked.value);
  },
});
</script>

<style lang="scss" scoped>
.QuestionnaireRadio {
  &__error {
    font-size: $service-font-size-pp;
    line-height: $service-line-height-mf;
    color: $color-red-70;
  }

  &__list {
    appearance: none;
  }

  &__error + &__list {
    margin-top: $service-unit * 3;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:first-child) {
      margin-top: $service-unit * 3;
    }

    &:hover {
      background-color: $color-gray-20;
    }
  }

  &__input {
    @include hidden;
  }

  &__menuLabel {
    display: flex;
    align-items: center;
    width: 100%;
    height: $service-unit * 12;
    padding: 0 $service-unit * 4;
    cursor: pointer;
    border: 1px solid $color-gray-50;
    border-radius: $service-border-radius;
  }

  & &__icon {
    width: $service-unit * 6;
    height: $service-unit * 6;
  }

  &__text {
    display: inline-flex;
    margin-left: $service-unit * 2;
    font-size: $service-font-size-mp;
    font-weight: bold;
    line-height: $service-line-height-mf;
    color: $color-gray-80;
  }

  &__item--select:hover {
    background-color: $color-blue-10;
  }

  &__item--select &__menuLabel {
    background-color: $color-blue-10;
    border: 1px solid $color-blue-60;
  }

  &__input:focus-visible + &__menuLabel {
    outline: -webkit-focus-ring-color auto 1px;
  }

  &__item--select &__icon {
    color: $color-blue-60;
  }
}
</style>
